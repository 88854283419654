
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import dashboardApi from "@/core/services/DashboardApi";
import { ElMessage } from "element-plus";

export default defineComponent({
    name: "statistics",
    components: {},
    data() {
        return {
            membersExport: {} as any,
            duesExport: {} as any,
            loadingExportMembers: false,
            loadingExportDues: false,
        };
    },
    setup() {
        onMounted(() => {
            setCurrentPageBreadcrumbs("Statistics", []);
        });
        return {};
    },
    methods: {
        exportFile(scope: string) {
            let payload = { stats_type: scope, status: ["active"], is_F_spouse: true } as any;

            if (scope === "members") {
                if (!this.membersExport.date || !this.membersExport.date1) {
                    ElMessage({
                        message: "Please select a date range",
                        type: "error",
                    });
                } else {
                    payload = { ...payload, ...this.membersExport };
                    payload.is_omgd = payload.is_omgd === "OMGD" ? true : false;
                    this.loadingExportMembers = true;
                    dashboardApi.exportStats(payload).then((res) => {
                        window.open(res.data.stats_csv, "_blank")?.focus();
                        this.loadingExportMembers = false;
                    });
                }
            } else if (scope === "dues") {
                if (!this.duesExport.date || !this.duesExport.date1) {
                    ElMessage({
                        message: "Please select a date range",
                        type: "error",
                    });
                } else {
                    payload = { ...payload, ...this.duesExport };
                    delete payload.status;
                    delete payload.is_F_spouse;
                    this.loadingExportDues = true;
                    dashboardApi.exportStats(payload).then((res) => {
                        window.open(res.data.stats_csv, "_blank")?.focus();
                        this.loadingExportDues = false;
                    });
                }
            }
        },
    },
});
